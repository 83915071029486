import React from "react"
import classNames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface IAvatarLabel {}

const AvatarLabel: FuzzyFunctionalComponent<IAvatarLabel> = ({ children, className, ...props }) => {
  return (
    <div className={classNames("fuze-avatar-label", className)} {...props}>
      {children}
    </div>
  )
}

export default AvatarLabel
