import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"

interface InternalITabPanel {}

const InternalTabPanel: FuzzyFunctionalComponent<InternalITabPanel> = ({ children, className, hidden = false, id }) => {
  return (
    <div
      aria-labelledby={`tab-${id}`}
      className={classNames("fuze-tabs__panel", className)}
      hidden={hidden}
      id={`panel-${id}`}
      role="tabpanel"
      tabIndex={0}
    >
      {children}
    </div>
  )
}

export default InternalTabPanel
