import React, { createContext } from "react"
import { useLoginUrl, useLogoutUrl } from "./useLoginUrl"
import { PermissionsProvider } from "../permissions/PermissionsProvider"
import { useAuthentication } from "./useAuthentication"
import { useConfigurationContext } from "./useConfigurationContext"

export const AuthenticationContext = createContext({})

/**
 * This provider wraps the entire App and is responsible for conditionally showing
 * authentication related redirects/errors. The reason that it looks for the
 * suspended status is because that comes back as an error from the first
 * Foundry call (get user)
 */
export function AuthenticationProvider({ children }) {
  const [status, loading] = useAuthentication()
  const loginUrl = useLoginUrl()
  const [isLoggingOut, logoutUrl, logout] = useLogoutUrl()
  const { Components } = useConfigurationContext()

  if (loading) {
    return <Components.PageSpinner />
  }

  if (isLoggingOut) {
    window.location.href = logoutUrl
    return null
  }

  if (status === "login") {
    window.location.href = loginUrl
    return null
  }

  return (
    <AuthenticationContext.Provider value={{ logout }}>
      <AuthenticationError status={status}>
        <PermissionsProvider>{children}</PermissionsProvider>
      </AuthenticationError>
    </AuthenticationContext.Provider>
  )
}

function AuthenticationError({ status = "error", children }) {
  const { Components } = useConfigurationContext()

  const errors = {
    error: "GeneralErrorPage",
    suspended: "SuspendedOrganization",
    unauthorized: "ErrorUnauthorized"
  }

  const ErrorComponent = Components[errors[status]]

  if (status !== "ready") {
    return <ErrorComponent />
  }

  return children
}
