import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"
import InternalTabPanel from "../Tabs/InternalTabPanel"

interface INavigationTabsPanel {
  exact?: boolean
  path: string
}

const NavigationTabsPanel: FuzzyFunctionalComponent<INavigationTabsPanel> = ({
  children,
  className,
  exact = false,
  path,
  ...props
}) => {
  return (
    <InternalTabPanel
      className={classNames("fuze-tab-panel fuze-navigation-tabs-panel fuze-tab-panel--active", className)}
      {...props}
    >
      {children}
    </InternalTabPanel>
  )
}

export default NavigationTabsPanel
