import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import InternalCheckbox, { IInternalCheckbox } from "./InternalCheckbox"

interface IAvatarToggle {}

const AvatarToggle: FuzzyFunctionalComponent<IInternalCheckbox & IAvatarToggle> = ({
  className,
  children,
  ...props
}) => {
  return (
    <InternalCheckbox className={classnames("fuze-avatar-toggle", className)} {...props}>
      {children}
    </InternalCheckbox>
  )
}

export default AvatarToggle
