import { useContext } from "react"
import { RouterContext } from "./Router"

/**
 * Given a path, is that path owned by the current application
 *
 * @param {string} path
 */
export function useIsInternalPath(path) {
  const { isInternalPath } = useContext(RouterContext)
  return isInternalPath(path)
}
