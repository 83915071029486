import React, { useState } from "react"
import { Dropdown, DropdownMenu, DropdownProps, DropdownToggle } from "reactstrap"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface MoreMenuProps {
  ariaLabel?: string
}

const MoreMenu: FuzzyFunctionalComponent<MoreMenuProps & DropdownProps> = ({
  ariaLabel,
  children,
  className,
  direction = "left",
  ...props
}): any => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <Dropdown
      className={classnames("more-menu", className)}
      direction={direction}
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      {...props}
    >
      <DropdownToggle aria-label={ariaLabel} color="transparent" className="fuze-more-menu__toggle">
        <i
          className={classnames("fuze-more-menu__toggle-icon", {
            "icon-other-options": !isOpen,
            "icon-other-options-open": isOpen,
          })}
        />
      </DropdownToggle>
      <DropdownMenu className="fuze-more-menu__dropdown-menu" onClick={() => setIsOpen(false)}>
        {children}
      </DropdownMenu>
    </Dropdown>
  )
}

export default MoreMenu
