import React from "react"
import { PermissionsContext } from "./PermissionsContext"
import { getUser } from "@fuze/services-auth/dist/user-session"
import { useConfigurationContext } from "../authentication/useConfigurationContext"

function PermissionsProvider({ children }) {
  const { Components } = useConfigurationContext()
  const user = getUser()

  if (!user || !user.permissions || user.permissions.length === 0) {
    return <Components.ErrorUnauthorized />
  }

  return <PermissionsContext.Provider value={{ permissions: user.permissions }}>{children}</PermissionsContext.Provider>
}

export { PermissionsProvider }
