import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface PageHeaderProps {
  className?: string
  color?: string
}

const PageHeader: FuzzyFunctionalComponent<PageHeaderProps> = ({ children, className, color = "grey", ...props }) => {
  return (
    <div className={classnames("fuze-page__header", className, `fuze-page__header--${color}`)} {...props}>
      {children}
    </div>
  )
}

export default PageHeader
