import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classnNames from "classnames"
import { Alignment } from "@enums"

interface INavigationTabsList {
  align: Alignment
  ariaLabel: string
}

const NavigationTabsList: FuzzyFunctionalComponent<INavigationTabsList> = ({
  align,
  ariaLabel,
  children,
  className,
  id,
  ...props
}) => {
  const alignMentClass = align && `fuze-tabs__tab-list--${align}`

  return (
    <nav
      aria-label={ariaLabel}
      className={classnNames(className, "fuze-tabs__navigation fuze-navigation-tabs__navigation")}
      id={id}
      {...props}
    >
      <ul className={classnNames("fuze-tabs__tab-list fuze-tabs__navigation-list", alignMentClass)}>{children}</ul>
    </nav>
  )
}

export default NavigationTabsList
