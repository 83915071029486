import React from "react"
import IconAgent from "./IconAgent"
import IconAlertHelpCircleFilled from "./IconAlertHelpCircleFilled"
import IconAlertHelpCircle from "./IconAlertHelpCircle"
import IconAlertInfoCircle from "./IconAlertInfoCircle"
import IconAlertWarningCircle from "./IconAlertWarningCircle"
import IconAlertWarningColor from "./IconAlertWarningColor"
import IconAlertWarning from "./IconAlertWarning"
import IconAntenna from "./IconAntenna"
import IconArrowDownLeft from "./IconArrowDownLeft"
import IconArrowLeft from "./IconArrowLeft"
import IconArrowNext from "./IconArrowNext"
import IconArrowPrevious from "./IconArrowPrevious"
import IconArrowRedo from "./IconArrowRedo"
import IconArrowRight from "./IconArrowRight"
import IconArrowUpLeftTail from "./IconArrowUpLeftTail"
import IconArrowUpRight from "./IconArrowUpRight"
import IconArrowsCompress from "./IconArrowsCompress"
import IconArrowsExchangeCircle from "./IconArrowsExchangeCircle"
import IconArrowsExpand from "./IconArrowsExpand"
import IconArrowsFitSquare from "./IconArrowsFitSquare"
import IconArrowsFitWidth from "./IconArrowsFitWidth"
import IconArrowsMerge from "./IconArrowsMerge"
import IconArrowsResend from "./IconArrowsResend"
import IconArrowsSortDown from "./IconArrowsSortDown"
import IconArrowsSortUp from "./IconArrowsSortUp"
import IconArrowsSort from "./IconArrowsSort"
import IconArrowsSwitch from "./IconArrowsSwitch"
import IconAt from "./IconAt"
import IconAvatarStatus from "./IconAvatarStatus"
import IconBackspace from "./IconBackspace"
import IconBellRinging from "./IconBellRinging"
import IconBellSlash from "./IconBellSlash"
import IconBell from "./IconBell"
import IconBluetooth from "./IconBluetooth"
import IconBookmark from "./IconBookmark"
import IconBriefcase from "./IconBriefcase"
import IconBuildingCar from "./IconBuildingCar"
import IconBuilding from "./IconBuilding"
import IconCalendarEventPlus from "./IconCalendarEventPlus"
import IconCalendarEvent from "./IconCalendarEvent"
import IconCameraSwitch from "./IconCameraSwitch"
import IconCamera from "./IconCamera"
import IconCap from "./IconCap"
import IconCar from "./IconCar"
import IconChartBar from "./IconChartBar"
import IconChartLine from "./IconChartLine"
import IconChartPie from "./IconChartPie"
import IconChatArrowLeft from "./IconChatArrowLeft"
import IconChatDashed from "./IconChatDashed"
import IconChatHeadphones from "./IconChatHeadphones"
import IconChatPause from "./IconChatPause"
import IconChatPlus from "./IconChatPlus"
import IconChat from "./IconChat"
import IconChecklist from "./IconChecklist"
import IconCheckmarkAltCircle from "./IconCheckmarkAltCircle"
import IconCheckmarkCircle from "./IconCheckmarkCircle"
import IconCheckmark from "./IconCheckmark"
import IconChevronDown from "./IconChevronDown"
import IconChevronLeft from "./IconChevronLeft"
import IconChevronRight from "./IconChevronRight"
import IconChevronSmallDown from "./IconChevronSmallDown"
import IconChevronSmallLeft from "./IconChevronSmallLeft"
import IconChevronSmallRight from "./IconChevronSmallRight"
import IconChevronSmallUp from "./IconChevronSmallUp"
import IconChevronUp from "./IconChevronUp"
import IconClockHistory from "./IconClockHistory"
import IconClock from "./IconClock"
import IconCloudDownload from "./IconCloudDownload"
import IconCloudUpload from "./IconCloudUpload"
import IconCog from "./IconCog"
import IconComputerArrowUpTimes from "./IconComputerArrowUpTimes"
import IconComputerArrowUp from "./IconComputerArrowUp"
import IconComputerCog from "./IconComputerCog"
import IconComputerSpeaker from "./IconComputerSpeaker"
import IconCopy from "./IconCopy"
import IconDeskphone from "./IconDeskphone"
import IconDialpad from "./IconDialpad"
import IconDollarMenu from "./IconDollarMenu"
import IconEllipsisVertical from "./IconEllipsisVertical"
import IconEmailHeadphones from "./IconEmailHeadphones"
import IconEmailPauseDashed from "./IconEmailPauseDashed"
import IconEmailPaused from "./IconEmailPaused"
import IconEmailSlash from "./IconEmailSlash"
import IconEmail from "./IconEmail"
import IconEmojiPlus from "./IconEmojiPlus"
import IconEmoji from "./IconEmoji"
import IconExclamationBaloon from "./IconExclamationBaloon"
import IconEyeSlash from "./IconEyeSlash"
import IconEye from "./IconEye"
import IconFileDownload from "./IconFileDownload"
import IconFileImageDownload from "./IconFileImageDownload"
import IconFileImageTimes from "./IconFileImageTimes"
import IconFileImageUpload from "./IconFileImageUpload"
import IconFileImage from "./IconFileImage"
import IconFileMagnifyingGlass from "./IconFileMagnifyingGlass"
import IconFileTimes from "./IconFileTimes"
import IconFileUpload from "./IconFileUpload"
import IconFile from "./IconFile"
import IconFilesDownload from "./IconFilesDownload"
import IconFilesUpload from "./IconFilesUpload"
import IconFlagAlt from "./IconFlagAlt"
import IconFlagTimesAll from "./IconFlagTimesAll"
import IconFlagTimes from "./IconFlagTimes"
import IconFlag from "./IconFlag"
import IconFunnel from "./IconFunnel"
import IconFuze from "./IconFuze"
import IconGlobe from "./IconGlobe"
import IconHeadphonesCheckmarkAll from "./IconHeadphonesCheckmarkAll"
import IconHeadphonesDashed from "./IconHeadphonesDashed"
import IconHeadphonesPause from "./IconHeadphonesPause"
import IconHeadphonesWireless from "./IconHeadphonesWireless"
import IconHeadphones from "./IconHeadphones"
import IconHeartFilled from "./IconHeartFilled"
import IconHeart from "./IconHeart"
import IconHexagonsAttached from "./IconHexagonsAttached"
import IconHouse from "./IconHouse"
import IconIntegration from "./IconIntegration"
import IconLadybird from "./IconLadybird"
import IconLaptopChartLine from "./IconLaptopChartLine"
import IconLaptop from "./IconLaptop"
import IconLayoutLeftPanel from "./IconLayoutLeftPanel"
import IconLayoutList from "./IconLayoutList"
import IconLayoutMinimized from "./IconLayoutMinimized"
import IconLayoutTopPanel from "./IconLayoutTopPanel"
import IconLayoutUserBottomPanel from "./IconLayoutUserBottomPanel"
import IconLayoutUserRightPanel from "./IconLayoutUserRightPanel"
import IconLayoutUser from "./IconLayoutUser"
import IconLightbulb from "./IconLightbulb"
import IconLinkExternal from "./IconLinkExternal"
import IconLink from "./IconLink"
import IconListItemUnread from "./IconListItemUnread"
import IconLockOpen from "./IconLockOpen"
import IconLock from "./IconLock"
import IconLogOut from "./IconLogOut"
import IconLoudspeaker from "./IconLoudspeaker"
import IconMagnifyingGlassExclamation from "./IconMagnifyingGlassExclamation"
import IconMagnifyingGlassMinus from "./IconMagnifyingGlassMinus"
import IconMagnifyingGlassPlus from "./IconMagnifyingGlassPlus"
import IconMagnifyingGlass from "./IconMagnifyingGlass"
import IconMediaNext from "./IconMediaNext"
import IconMediaPause from "./IconMediaPause"
import IconMediaPlayRectangle from "./IconMediaPlayRectangle"
import IconMediaPlay from "./IconMediaPlay"
import IconMediaPrevious from "./IconMediaPrevious"
import IconMediaRecordColor from "./IconMediaRecordColor"
import IconMediaRecordStopColor from "./IconMediaRecordStopColor"
import IconMediaRecordStop from "./IconMediaRecordStop"
import IconMediaRecord from "./IconMediaRecord"
import IconMenuStatusAvailable from "./IconMenuStatusAvailable"
import IconMenuStatusAway from "./IconMenuStatusAway"
import IconMenuStatusBusy from "./IconMenuStatusBusy"
import IconMenuStatusDnd from "./IconMenuStatusDnd"
import IconMenuStatusOoo from "./IconMenuStatusOoo"
import IconMenu from "./IconMenu"
import IconMicrophoneLockSlash from "./IconMicrophoneLockSlash"
import IconMicrophoneSlashMono from "./IconMicrophoneSlashMono"
import IconMicrophoneSlash from "./IconMicrophoneSlash"
import IconMicrophoneWave from "./IconMicrophoneWave"
import IconMicrophone from "./IconMicrophone"
import IconMicrophonesSlash from "./IconMicrophonesSlash"
import IconMinusCircle from "./IconMinusCircle"
import IconMinus from "./IconMinus"
import IconMobileList from "./IconMobileList"
import IconMobile from "./IconMobile"
import IconMouseEmitSignalSlash from "./IconMouseEmitSignalSlash"
import IconMouseEmitSignal from "./IconMouseEmitSignal"
import IconMugTea from "./IconMugTea"
import IconNetworkSignal from "./IconNetworkSignal"
import IconNetworkWifi from "./IconNetworkWifi"
import IconNetworkWiredAlt from "./IconNetworkWiredAlt"
import IconNotes from "./IconNotes"
import IconOneByOne from "./IconOneByOne"
import IconPaperclip from "./IconPaperclip"
import IconPencilEdit from "./IconPencilEdit"
import IconPencil from "./IconPencil"
import IconPhoneArrowCurveLeft from "./IconPhoneArrowCurveLeft"
import IconPhoneArrowLeft from "./IconPhoneArrowLeft"
import IconPhoneArrowRight from "./IconPhoneArrowRight"
import IconPhoneArrowTop from "./IconPhoneArrowTop"
import IconPhoneClock from "./IconPhoneClock"
import IconPhoneDown from "./IconPhoneDown"
import IconPhoneEllipsis from "./IconPhoneEllipsis"
import IconPhoneHash from "./IconPhoneHash"
import IconPhonePlug from "./IconPhonePlug"
import IconPhonePlus from "./IconPhonePlus"
import IconPhoneReceiveSignal from "./IconPhoneReceiveSignal"
import IconPhoneRing from "./IconPhoneRing"
import IconPhone from "./IconPhone"
import IconPictureInPicture from "./IconPictureInPicture"
import IconPinLocation from "./IconPinLocation"
import IconPinSlash from "./IconPinSlash"
import IconPin from "./IconPin"
import IconPlusCircle from "./IconPlusCircle"
import IconPlus from "./IconPlus"
import IconSalesforce from "./IconSalesforce"
import IconShoppingCart from "./IconShoppingCart"
import IconSignalWave from "./IconSignalWave"
import IconSpeakerSlashMono from "./IconSpeakerSlashMono"
import IconSpeakerSlash from "./IconSpeakerSlash"
import IconSpeaker from "./IconSpeaker"
import IconSquaresGrid from "./IconSquaresGrid"
import IconStarFilled from "./IconStarFilled"
import IconStarSlash from "./IconStarSlash"
import IconStar from "./IconStar"
import IconStatusAvailable from "./IconStatusAvailable"
import IconStatusAway from "./IconStatusAway"
import IconStatusBusy from "./IconStatusBusy"
import IconStatusDnd from "./IconStatusDnd"
import IconStatusOffline from "./IconStatusOffline"
import IconStatusOoo from "./IconStatusOoo"
import IconStopwatch from "./IconStopwatch"
import IconSymbols from "./IconSymbols"
import IconTable from "./IconTable"
import IconTachometer from "./IconTachometer"
import IconTimesSmall from "./IconTimesSmall"
import IconTimesSquare from "./IconTimesSquare"
import IconTimes from "./IconTimes"
import IconTouch from "./IconTouch"
import IconTrash from "./IconTrash"
import IconTrophy from "./IconTrophy"
import IconUserAgent from "./IconUserAgent"
import IconUserCheckmark from "./IconUserCheckmark"
import IconUserGuest from "./IconUserGuest"
import IconUserPlus from "./IconUserPlus"
import IconUserSwitch from "./IconUserSwitch"
import IconUserTimes from "./IconUserTimes"
import IconUser from "./IconUser"
import IconUsersGroupPlus from "./IconUsersGroupPlus"
import IconUsersGroup from "./IconUsersGroup"
import IconUsers from "./IconUsers"
import IconVideoLockSlash from "./IconVideoLockSlash"
import IconVideoPlus from "./IconVideoPlus"
import IconVideoSlash from "./IconVideoSlash"
import IconVideoTimes from "./IconVideoTimes"
import IconVideo from "./IconVideo"
import IconVoicemail from "./IconVoicemail"
import IconWebhookMono from "./IconWebhookMono"
import IconWebhook from "./IconWebhook"
import IconWindowDock from "./IconWindowDock"
import IconWindowUndock from "./IconWindowUndock"
import IconWindow from "./IconWindow"

function DemoIcons() {
  return (
    <div className="grid-container" style={{ display: "flex", "flex-wrap": "wrap" }}>
      
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconAgent fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconAgent</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconAlertHelpCircleFilled fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconAlertHelpCircleFilled</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconAlertHelpCircle fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconAlertHelpCircle</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconAlertInfoCircle fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconAlertInfoCircle</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconAlertWarningCircle fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconAlertWarningCircle</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconAlertWarningColor fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconAlertWarningColor</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconAlertWarning fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconAlertWarning</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconAntenna fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconAntenna</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowDownLeft fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowDownLeft</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowLeft fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowLeft</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowNext fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowNext</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowPrevious fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowPrevious</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowRedo fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowRedo</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowRight fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowRight</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowUpLeftTail fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowUpLeftTail</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowUpRight fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowUpRight</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowsCompress fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowsCompress</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowsExchangeCircle fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowsExchangeCircle</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowsExpand fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowsExpand</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowsFitSquare fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowsFitSquare</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowsFitWidth fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowsFitWidth</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowsMerge fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowsMerge</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowsResend fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowsResend</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowsSortDown fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowsSortDown</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowsSortUp fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowsSortUp</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowsSort fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowsSort</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconArrowsSwitch fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconArrowsSwitch</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconAt fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconAt</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconAvatarStatus fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconAvatarStatus</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconBackspace fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconBackspace</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconBellRinging fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconBellRinging</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconBellSlash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconBellSlash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconBell fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconBell</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconBluetooth fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconBluetooth</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconBookmark fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconBookmark</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconBriefcase fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconBriefcase</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconBuildingCar fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconBuildingCar</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconBuilding fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconBuilding</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCalendarEventPlus fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCalendarEventPlus</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCalendarEvent fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCalendarEvent</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCameraSwitch fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCameraSwitch</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCamera fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCamera</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCap fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCap</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCar fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCar</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChartBar fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChartBar</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChartLine fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChartLine</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChartPie fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChartPie</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChatArrowLeft fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChatArrowLeft</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChatDashed fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChatDashed</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChatHeadphones fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChatHeadphones</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChatPause fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChatPause</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChatPlus fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChatPlus</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChat fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChat</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChecklist fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChecklist</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCheckmarkAltCircle fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCheckmarkAltCircle</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCheckmarkCircle fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCheckmarkCircle</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCheckmark fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCheckmark</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChevronDown fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChevronDown</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChevronLeft fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChevronLeft</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChevronRight fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChevronRight</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChevronSmallDown fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChevronSmallDown</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChevronSmallLeft fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChevronSmallLeft</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChevronSmallRight fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChevronSmallRight</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChevronSmallUp fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChevronSmallUp</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconChevronUp fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconChevronUp</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconClockHistory fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconClockHistory</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconClock fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconClock</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCloudDownload fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCloudDownload</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCloudUpload fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCloudUpload</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCog fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCog</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconComputerArrowUpTimes fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconComputerArrowUpTimes</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconComputerArrowUp fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconComputerArrowUp</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconComputerCog fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconComputerCog</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconComputerSpeaker fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconComputerSpeaker</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconCopy fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconCopy</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconDeskphone fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconDeskphone</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconDialpad fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconDialpad</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconDollarMenu fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconDollarMenu</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconEllipsisVertical fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconEllipsisVertical</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconEmailHeadphones fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconEmailHeadphones</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconEmailPauseDashed fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconEmailPauseDashed</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconEmailPaused fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconEmailPaused</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconEmailSlash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconEmailSlash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconEmail fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconEmail</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconEmojiPlus fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconEmojiPlus</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconEmoji fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconEmoji</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconExclamationBaloon fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconExclamationBaloon</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconEyeSlash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconEyeSlash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconEye fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconEye</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFileDownload fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFileDownload</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFileImageDownload fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFileImageDownload</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFileImageTimes fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFileImageTimes</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFileImageUpload fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFileImageUpload</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFileImage fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFileImage</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFileMagnifyingGlass fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFileMagnifyingGlass</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFileTimes fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFileTimes</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFileUpload fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFileUpload</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFile fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFile</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFilesDownload fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFilesDownload</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFilesUpload fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFilesUpload</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFlagAlt fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFlagAlt</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFlagTimesAll fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFlagTimesAll</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFlagTimes fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFlagTimes</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFlag fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFlag</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFunnel fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFunnel</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconFuze fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconFuze</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconGlobe fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconGlobe</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconHeadphonesCheckmarkAll fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconHeadphonesCheckmarkAll</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconHeadphonesDashed fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconHeadphonesDashed</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconHeadphonesPause fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconHeadphonesPause</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconHeadphonesWireless fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconHeadphonesWireless</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconHeadphones fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconHeadphones</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconHeartFilled fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconHeartFilled</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconHeart fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconHeart</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconHexagonsAttached fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconHexagonsAttached</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconHouse fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconHouse</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconIntegration fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconIntegration</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLadybird fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLadybird</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLaptopChartLine fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLaptopChartLine</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLaptop fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLaptop</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLayoutLeftPanel fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLayoutLeftPanel</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLayoutList fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLayoutList</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLayoutMinimized fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLayoutMinimized</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLayoutTopPanel fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLayoutTopPanel</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLayoutUserBottomPanel fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLayoutUserBottomPanel</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLayoutUserRightPanel fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLayoutUserRightPanel</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLayoutUser fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLayoutUser</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLightbulb fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLightbulb</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLinkExternal fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLinkExternal</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLink fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLink</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconListItemUnread fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconListItemUnread</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLockOpen fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLockOpen</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLock fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLock</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLogOut fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLogOut</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconLoudspeaker fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconLoudspeaker</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMagnifyingGlassExclamation fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMagnifyingGlassExclamation</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMagnifyingGlassMinus fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMagnifyingGlassMinus</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMagnifyingGlassPlus fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMagnifyingGlassPlus</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMagnifyingGlass fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMagnifyingGlass</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMediaNext fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMediaNext</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMediaPause fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMediaPause</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMediaPlayRectangle fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMediaPlayRectangle</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMediaPlay fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMediaPlay</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMediaPrevious fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMediaPrevious</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMediaRecordColor fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMediaRecordColor</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMediaRecordStopColor fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMediaRecordStopColor</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMediaRecordStop fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMediaRecordStop</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMediaRecord fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMediaRecord</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMenuStatusAvailable fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMenuStatusAvailable</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMenuStatusAway fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMenuStatusAway</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMenuStatusBusy fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMenuStatusBusy</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMenuStatusDnd fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMenuStatusDnd</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMenuStatusOoo fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMenuStatusOoo</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMenu fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMenu</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMicrophoneLockSlash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMicrophoneLockSlash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMicrophoneSlashMono fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMicrophoneSlashMono</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMicrophoneSlash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMicrophoneSlash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMicrophoneWave fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMicrophoneWave</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMicrophone fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMicrophone</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMicrophonesSlash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMicrophonesSlash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMinusCircle fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMinusCircle</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMinus fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMinus</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMobileList fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMobileList</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMobile fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMobile</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMouseEmitSignalSlash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMouseEmitSignalSlash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMouseEmitSignal fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMouseEmitSignal</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconMugTea fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconMugTea</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconNetworkSignal fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconNetworkSignal</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconNetworkWifi fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconNetworkWifi</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconNetworkWiredAlt fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconNetworkWiredAlt</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconNotes fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconNotes</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconOneByOne fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconOneByOne</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPaperclip fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPaperclip</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPencilEdit fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPencilEdit</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPencil fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPencil</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhoneArrowCurveLeft fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhoneArrowCurveLeft</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhoneArrowLeft fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhoneArrowLeft</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhoneArrowRight fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhoneArrowRight</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhoneArrowTop fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhoneArrowTop</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhoneClock fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhoneClock</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhoneDown fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhoneDown</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhoneEllipsis fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhoneEllipsis</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhoneHash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhoneHash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhonePlug fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhonePlug</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhonePlus fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhonePlus</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhoneReceiveSignal fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhoneReceiveSignal</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhoneRing fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhoneRing</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPhone fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPhone</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPictureInPicture fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPictureInPicture</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPinLocation fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPinLocation</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPinSlash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPinSlash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPin fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPin</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPlusCircle fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPlusCircle</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconPlus fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconPlus</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconSalesforce fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconSalesforce</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconShoppingCart fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconShoppingCart</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconSignalWave fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconSignalWave</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconSpeakerSlashMono fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconSpeakerSlashMono</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconSpeakerSlash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconSpeakerSlash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconSpeaker fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconSpeaker</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconSquaresGrid fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconSquaresGrid</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconStarFilled fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconStarFilled</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconStarSlash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconStarSlash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconStar fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconStar</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconStatusAvailable fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconStatusAvailable</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconStatusAway fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconStatusAway</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconStatusBusy fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconStatusBusy</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconStatusDnd fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconStatusDnd</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconStatusOffline fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconStatusOffline</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconStatusOoo fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconStatusOoo</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconStopwatch fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconStopwatch</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconSymbols fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconSymbols</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconTable fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconTable</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconTachometer fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconTachometer</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconTimesSmall fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconTimesSmall</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconTimesSquare fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconTimesSquare</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconTimes fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconTimes</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconTouch fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconTouch</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconTrash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconTrash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconTrophy fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconTrophy</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconUserAgent fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconUserAgent</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconUserCheckmark fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconUserCheckmark</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconUserGuest fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconUserGuest</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconUserPlus fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconUserPlus</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconUserSwitch fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconUserSwitch</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconUserTimes fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconUserTimes</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconUser fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconUser</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconUsersGroupPlus fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconUsersGroupPlus</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconUsersGroup fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconUsersGroup</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconUsers fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconUsers</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconVideoLockSlash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconVideoLockSlash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconVideoPlus fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconVideoPlus</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconVideoSlash fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconVideoSlash</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconVideoTimes fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconVideoTimes</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconVideo fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconVideo</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconVoicemail fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconVoicemail</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconWebhookMono fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconWebhookMono</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconWebhook fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconWebhook</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconWindowDock fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconWindowDock</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconWindowUndock fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconWindowUndock</div>
    </div>
  
    <div className="grid-item" style={{"align-items": "center", border: "1px solid #ccc", display: "flex", "flex-direction": "column", height: "12rem", "justify-content": "center", width: "12rem"}}>
      <div className="grid-item__icon">
        <IconWindow fontSize="3rem" color="#2a2a2a" color2="red" />
      </div>
      <div className="grid-item__icon-name" style={{fontSize: "10px", "margin-top": "1rem"}}>IconWindow</div>
    </div>
  
    </div>
  )
}

export default DemoIcons
