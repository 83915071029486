import React from "react"
import classnames from "classnames"
import { Spinner as ReactstrapSpinner } from "reactstrap"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import { Colors } from "@enums"

interface ISpinner {
  color?: Colors
  size?: string
}

const Spinner: FuzzyFunctionalComponent<ISpinner> = ({ color, children, className, size = "2em", ...props }) => {
  return (
    <ReactstrapSpinner
      className={classnames("fuze-spinner", className, color && `fuze-spinner--${color}`)}
      style={{
        borderWidth: `calc(${size} / 10)`,
        height: size,
        width: size,
      }}
      {...props}
    >
      {children}
    </ReactstrapSpinner>
  )
}

export default Spinner
