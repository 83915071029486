import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface PanelInsetProps {}

const PanelInset: FuzzyFunctionalComponent<PanelInsetProps | HTMLSpanElement> = ({ children, className, ...props }) => {
  return (
    <span className={classnames("inset panel__inset", className)} {...props}>
      {children}
    </span>
  )
}

export default PanelInset
