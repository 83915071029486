import React from "react"
import classnames from "classnames"
import PanelListItem from "./PanelListItem"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface PanelHeaderProps {}

const PanelHeader: FuzzyFunctionalComponent<PanelHeaderProps> = ({ children, className, ...props }) => {
  return (
    <PanelListItem className={classnames("header panel__header", className)} showWhileLoading {...props}>
      {children}
    </PanelListItem>
  )
}

export default PanelHeader
