import { useContext } from "react"
import { PermissionsContext } from "./PermissionsContext"
import { hasSomePermission } from "@fuze/services-auth/dist/permissions"

export function usePermissions() {
  const { permissions } = useContext(PermissionsContext)
  return permissions
}

export function useHasPermissions(requiredPermissions, permissionsCheck = hasSomePermission) {
  const userPermissions = usePermissions()
  return permissionsCheck(userPermissions, requiredPermissions)
}

/**
 * Base hook for writing access hooks
 *
 * Usage:
 * const usePathAccess = () => useAccess(["path.permission1", "path.permission2"], "/path")
 *
 * Using this hook will return a tuple with a boolean of whether the user is allowed access and the path
 *
 * @param {string[]} requiredPermissions A list of permission strings to check for
 * @param {string} path The path to be accessed
 * @param {function} permissionsCheck A function that returns if the user has the required permissions to access the children
 * By default, the permissionsCheck will return true if any of the permissions match
 *
 */
export function useAccess(requiredPermissions, path, permissionsCheck) {
  const hasPermissions = useHasPermissions(requiredPermissions, permissionsCheck)
  return [hasPermissions, path]
}
