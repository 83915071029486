import React from "react"
import { useToggle } from "react-use"
import classNames from "classnames"
import Panel from "./Panel"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface TogglePanelProps {}

interface ITogglePanelContext {
  toggled: boolean
  setToggled: Function
}

export const TogglePanelContext = React.createContext<ITogglePanelContext>(undefined)

const TogglePanel: FuzzyFunctionalComponent<TogglePanelProps> = ({ children, className, ...props }) => {
  const [toggled, setToggled] = useToggle(false)

  return (
    <TogglePanelContext.Provider value={{ toggled, setToggled }}>
      <Panel className={classNames(className, "panel--toggle-panel")} {...props}>
        {children}
      </Panel>
    </TogglePanelContext.Provider>
  )
}

export default TogglePanel
