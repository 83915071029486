import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import InternalAvatarOverlay from "./InternalAvatarOverlay"

interface IAvatarImage {
  backgroundImage: string
}

const AvatarImage: FuzzyFunctionalComponent<IAvatarImage> = ({ backgroundImage }) => {
  return (
    <InternalAvatarOverlay
      className="fuze-avatar__overlay--image"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    />
  )
}

export default AvatarImage
