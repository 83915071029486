import React, { useContext } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"
import { TabsContext } from "./Tabs"
import InternalTabPanel from "./InternalTabPanel"

interface ITabPanel {
  id: string | number
}

const TabPanel: FuzzyFunctionalComponent<ITabPanel> = ({ children, className, id, ...props }) => {
  const tabsContext = useContext(TabsContext)
  const activeTab = tabsContext?.activeTab
  const hidden = activeTab !== id

  return (
    <InternalTabPanel
      className={classNames(className, {
        "fuze-tab-panel--active": !hidden,
      })}
      hidden={hidden}
      {...props}
    >
      {children}
    </InternalTabPanel>
  )
}

export default TabPanel
