import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import MoreMenu from "../MoreMenu/MoreMenu"

interface PanelListItemMenuProps {}

const PanelListItemMenu: FuzzyFunctionalComponent<PanelListItemMenuProps> = ({ children, className, ...props }) => {
  return (
    <MoreMenu className={classnames("panel__menu", className)} {...props}>
      {children}
    </MoreMenu>
  )
}

export default PanelListItemMenu
