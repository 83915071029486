import React from "react"
import classnames from "classnames"
import { ModalBody, ModalBodyProps } from "reactstrap"
import { FuzzyFunctionalComponent } from "@interfaces/common"

type FuzeModalBodyProps = ModalBodyProps & {
  className?: string
}
const FuzeModalBody: FuzzyFunctionalComponent<FuzeModalBodyProps> = ({ className, ...props }) => {
  return <ModalBody className={classnames(className, "fuze-modal__body")} {...props} />
}

export { FuzeModalBody as ModalBody }
