import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface NotificationCardProps {
  imageSrc: string
}

const NotificationCard: FuzzyFunctionalComponent<NotificationCardProps> = ({
  className,
  children,
  imageSrc,
  ...props
}) => {
  return (
    <div className={classnames("notification-card", className)} {...props}>
      <img className="notification-card__image" src={imageSrc} alt="" {...props} />
      {children}
    </div>
  )
}

export default NotificationCard
