import React, { FunctionComponent } from "react"
import classNames from "classnames"
import {
  CardProps,
  CardBodyProps,
  CardColumnsProps,
  CardDeckProps,
  CardFooterProps,
  CardGroupProps,
  CardHeaderProps,
  CardImgProps,
  CardImgOverlayProps,
  CardLinkProps,
  CardSubtitleProps,
  CardTextProps,
  CardTitleProps,
  Card as ReactStrapCard,
  CardBody as ReactStrapCardBody,
  CardColumns as ReactStrapCardColumns,
  CardDeck as ReactStrapCardDeck,
  CardFooter as ReactStrapCardFooter,
  CardGroup as ReactStrapCardGroup,
  CardHeader as ReactStrapCardHeader,
  CardImg as ReactStrapCardImg,
  CardImgOverlay as ReactStrapCardImgOverlay,
  CardLink as ReactStrapCardLink,
  CardSubtitle as ReactStrapCardSubtitle,
  CardText as ReactStrapCardText,
  CardTitle as ReactStrapCardTitle,
} from "reactstrap"

export const Card: FunctionComponent<CardProps> = ({ className, ...props }) => (
  <ReactStrapCard className={classNames("fuze-card", className)} {...props} />
)

export const CardBody: FunctionComponent<CardBodyProps> = ({ className, ...props }) => (
  <ReactStrapCardBody className={classNames("fuze-card-body", className)} {...props} />
)

export const CardColumns: FunctionComponent<CardColumnsProps> = ({ className, ...props }) => (
  <ReactStrapCardColumns className={classNames("fuze-card-columns", className)} {...props} />
)

export const CardDeck: FunctionComponent<CardDeckProps> = ({ className, ...props }) => (
  <ReactStrapCardDeck className={classNames("fuze-card-deck", className)} {...props} />
)

export const CardFooter: FunctionComponent<CardFooterProps> = ({ className, ...props }) => (
  <ReactStrapCardFooter className={classNames("fuze-card-footer", className)} {...props} />
)

export const CardGroup: FunctionComponent<CardGroupProps> = ({ className, ...props }) => (
  <ReactStrapCardGroup className={classNames("fuze-card-group", className)} {...props} />
)

export const CardHeader: FunctionComponent<CardHeaderProps> = ({ className, ...props }) => (
  <ReactStrapCardHeader className={classNames("fuze-card-header", className)} {...props} />
)

export const CardImg: FunctionComponent<CardImgProps> = ({ className, ...props }) => (
  <ReactStrapCardImg className={classNames("fuze-card-img", className)} {...props} />
)

export const CardImgOverlay: FunctionComponent<CardImgOverlayProps> = ({ className, ...props }) => (
  <ReactStrapCardImgOverlay className={classNames("fuze-card-img-overlay", className)} {...props} />
)

export const CardLink: FunctionComponent<CardLinkProps> = ({ className, ...props }) => (
  <ReactStrapCardLink className={classNames("fuze-card-link", className)} {...props} />
)

export const CardSubtitle: FunctionComponent<CardSubtitleProps> = ({ className, ...props }) => (
  <ReactStrapCardSubtitle className={classNames("fuze-card-subtitle", className)} {...props} />
)

export const CardText: FunctionComponent<CardTextProps> = ({ className, ...props }) => (
  <ReactStrapCardText className={classNames("fuze-card-text", className)} {...props} />
)

export const CardTitle: FunctionComponent<CardTitleProps> = ({ className, ...props }) => (
  <ReactStrapCardTitle className={classNames("fuze-card-title", className)} {...props} />
)
