import React, { useContext } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import { TogglePanelContext } from "./TogglePanel"

interface ToggledContentProps {}

const ToggledContent: FuzzyFunctionalComponent<ToggledContentProps> = ({ children }) => {
  const { toggled } = useContext(TogglePanelContext)

  return (
    <>
      {React.Children.map(children, (element: JSX.Element, index: number) => {
        // Toggle panel displays 1 of 2 sets of content at a time.
        // Toggle panel has 2 states, toggled and !toggled.
        // Here, we compare index as a boolean to the toggled boolean.
        // When index is 0, !!0 will be false, meaning it will not display if toggled is truthy.
        // When index is 1, !!1 will be true, meaning it will not display when toggled is falsey.
        if (!!index !== toggled) {
          return null
        }

        return element
      })}
    </>
  )
}

export default ToggledContent
