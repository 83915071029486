import { useContext } from "react"
import { AuthorizationContext } from "./AuthorizationContext"

export function useIsRoutePermitted(to: string): boolean {
  const isRoutePermitted = useIsRoutePermittedFn()
  return isRoutePermitted(to)
}

export function useIsRoutePermittedFn(): (to: string) => boolean {
  const { isRoutePermitted } = useContext(AuthorizationContext)
  return isRoutePermitted
}

export function useRouteErrorHandler() {
  const { routeErrorHandler } = useContext(AuthorizationContext)
  return routeErrorHandler
}
