import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface PageFooterProps {
  className?: string
}

const PageFooter: FuzzyFunctionalComponent<PageFooterProps> = ({ children, className, ...props }) => {
  return (
    <div className={classnames("fuze-page__footer", className)} {...props}>
      {children}
    </div>
  )
}

export default PageFooter
