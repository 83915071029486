import React, { useEffect, useRef, useState } from "react"
import classnames from "classnames"
import PanelListItem from "./PanelListItem"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface PanelListScrollableProps {
  height: number
  maxHeight: string
}

const PanelListScrollable: FuzzyFunctionalComponent<PanelListScrollableProps> = ({
  children,
  className,
  maxHeight = "initial",
  ...props
}) => {
  return (
    <PanelListItem
      className={classnames("panel__list-item panel__list-item-scrollable", className)}
      style={{ maxHeight }}
      {...props}
    >
      <ul className="panel__list-item-scrollable-content">{children}</ul>
    </PanelListItem>
  )
}

export default PanelListScrollable
