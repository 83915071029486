import React, { createContext, useState } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"
import { inputValue } from "src/types"

interface IRadioGroupContext {
  name: string
  onClick(value: inputValue): void
  selectedValue: inputValue
}

export const RadioGroupContext = createContext<IRadioGroupContext>(undefined)

export interface IRadioGroup {
  defaultValue: inputValue
  name: string
  onChange(value: inputValue): void
}

const RadioGroup: FuzzyFunctionalComponent<IRadioGroup> = ({
  className,
  children,
  defaultValue,
  name,
  onChange,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  const onClick = (value: inputValue) => {
    if (selectedValue === value) return

    !!onChange && onChange(value)
    setSelectedValue(value)
  }

  return (
    <div className={classNames("fuze-radio-group", className)} {...props}>
      <RadioGroupContext.Provider value={{ onClick, name, selectedValue: selectedValue || defaultValue }}>
        {children}
      </RadioGroupContext.Provider>
    </div>
  )
}

export default RadioGroup
