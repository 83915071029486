import React, { createContext } from "react"
import { BrowserRouter, matchPath } from "react-router-dom"

const RouterContext = createContext({})

/**
 * The main Router component
 * Allows hub-router components to render internal or external links depending on which app the route belongs to
 *
 * @param {appName} string the app that this route belongs to
 * @param {routes} array A list of objects of the routes
 * @param {children} ReactNode node to render
 */
function Router({ isInternalPath, children }) {
  return (
    <BrowserRouter>
      <RouterContext.Provider value={{ isInternalPath }}>{children}</RouterContext.Provider>
    </BrowserRouter>
  )
}

export { Router, RouterContext }
