import React, { FunctionComponent } from "react"
import classnames from "classnames"
import { inputValue } from "src/types"

export interface InternalToggleProps {
  className?: string
  checked: boolean
  id: string
  name?: string
  onClick(event: any): void
  type: string
  value: inputValue
}

const InternalToggle: FunctionComponent<InternalToggleProps> = ({
  checked,
  children,
  className,
  id,
  onClick,
  ...props
}) => {
  return (
    <div
      className={classnames("fuze-toggle-element", className, {
        "fuze-toggle-element--checked": checked,
      })}
    >
      <input
        aria-labelledby={`${id}-content`}
        className="fuze-toggle-element__input"
        id={id}
        onChange={onClick}
        checked={checked}
        {...props}
      />
      <span className="fuze-toggle-element__content" onClick={onClick} id={`${id}-content`}>
        {children}
      </span>
    </div>
  )
}

export default InternalToggle
