import React, { createContext, useContext } from "react"

export const ConfigurationContext = createContext({
  warden: {},
  authenticationCallback: () => {},
  Components: {
    PageSpinner: () => <>Loading</>,
    GeneralErrorPage: () => <>General Error Page</>,
    ErrorUnauthorized: () => <>Error Unauthorized</>,
    SuspendedOrganization: () => <>Suspended Organization</>
  }
})

export function useConfigurationContext() {
  const { value } = useContext(ConfigurationContext)
  return { ...value }
}
