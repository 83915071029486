import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import InternalAvatarOverlay from "./InternalAvatarOverlay"

interface IAvatarIcon {}

const AvatarIcon: FuzzyFunctionalComponent<IAvatarIcon> = ({ children }) => {
  return <InternalAvatarOverlay className="fuze-avatar__overlay--icon">{children}</InternalAvatarOverlay>
}

export default AvatarIcon
