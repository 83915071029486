import React from "react"
import classnames from "classnames"
import PanelListItem from "./PanelListItem"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface PanelListItemAddProps {
  onClick: void
}

const PanelListItemAdd: FuzzyFunctionalComponent<PanelListItemAddProps> = ({
  children,
  className,
  onClick,
  ...props
}) => {
  return (
    <PanelListItem className={classnames("add-item panel__add", className)} {...props}>
      <button type="button" onClick={onClick}>
        <i className="icon-plus-2 panel__icon" />
        <span className="label add-item__label">{children}</span>
      </button>
    </PanelListItem>
  )
}

export default PanelListItemAdd
