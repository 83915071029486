import React from "react"
import classNames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

type Size = "small" | "large"

interface IAvatar {
  size?: Size
}

const Avatar: FuzzyFunctionalComponent<IAvatar> = ({ children, className, size, ...props }) => {
  const sizeClass = !!size && `fuze-avatar--${size}`

  return (
    <div className={classNames("fuze-avatar", className, sizeClass)} role="img" {...props}>
      {children}
    </div>
  )
}

export default Avatar
