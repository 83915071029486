import React, { useState } from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import InternalToggle, { InternalToggleProps } from "./InternalToggle"
import { useToggle } from "react-use"

export interface IInternalCheckbox extends InternalToggleProps {
  indeterminate?: boolean
  onChange(value: object): void
}

const InternalCheckbox: FuzzyFunctionalComponent<IInternalCheckbox> = ({
  checked,
  children,
  className,
  defaultChecked,
  indeterminate,
  onChange,
  ...props
}) => {
  const [isDirty, setIsDirty] = useToggle(false)

  const [isChecked, setIsChecked] = useState<boolean>(undefined)

  const checkedValue = ((!isDirty && defaultChecked) || checked) ?? isChecked

  return (
    <InternalToggle
      checked={checkedValue}
      className={classnames(className, {
        "fuze-toggle-element--indeterminate": indeterminate,
      })}
      onClick={handleOnClick}
      type="checkbox"
      {...props}
    >
      {children}
    </InternalToggle>
  )

  function handleOnClick(event: any) {
    const newValue = !checkedValue

    if (!isDirty) {
      setIsDirty()
    }

    if (onChange) {
      event.target.checked = newValue

      onChange(event)
    }

    setIsChecked(newValue)
  }
}

export default InternalCheckbox
