import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface PageProps {}

const Page: FuzzyFunctionalComponent<PageProps> = ({ children, className, ...props }) => {
  return (
    <div className={classnames("fuze-page", className)} {...props}>
      {children}
    </div>
  )
}

export default Page
