import React, { useContext } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import ToggledContent from "./ToggledContent"

interface TogglePanelContentProps {}

const TogglePanelContent: FuzzyFunctionalComponent<TogglePanelContentProps> = ({ children }) => (
  <ToggledContent>{children}</ToggledContent>
)

export default TogglePanelContent
