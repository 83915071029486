import React from "react"
import StarRating from "./StarRating"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface LargeStarRatingProps {
  arialLabel: string
  rating: number
}

const LargeStarRating: FuzzyFunctionalComponent<LargeStarRatingProps> = ({ arialLabel, children, rating }) => {
  if (!children || !rating) return null

  return (
    <div className="large-star-rating">
      <div className="large-star-rating__value">{rating}</div>
      <StarRating arialLabel={arialLabel} rating={rating} />
      <div className="large-star-rating__label">{children}</div>
    </div>
  )
}

export default LargeStarRating
