import React from "react"
import { AuthenticationProvider } from "./AuthenticationProvider"
import { ConfigurationContext } from "./useConfigurationContext"

function ConfigurationProvider({ children, ...value }) {
  return (
    <ConfigurationContext.Provider value={{ ...value }}>
      <AuthenticationProvider>{children}</AuthenticationProvider>
    </ConfigurationContext.Provider>
  )
}
export { ConfigurationProvider }
