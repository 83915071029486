import React, { useContext } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import { TogglePanelContext } from "./TogglePanel"
import ToggledContent from "./ToggledContent"

interface TogglePanelButtonsProps {}

const TogglePanelButtons: FuzzyFunctionalComponent<TogglePanelButtonsProps> = ({ children }) => {
  const { setToggled, toggled } = useContext(TogglePanelContext)

  return (
    <button type="button" className="button--transparent" onClick={() => setToggled(!toggled)}>
      <ToggledContent>{children}</ToggledContent>
    </button>
  )
}

export default TogglePanelButtons
