import React from "react"
import { Badge } from "reactstrap"
import classnames from "classnames"

import { FuzzyFunctionalComponent } from "@interfaces/common"
import { Colors } from "@enums"

interface MoreMenuBadgeProps {
  color: Colors
}

const MoreMenuBadge: FuzzyFunctionalComponent<MoreMenuBadgeProps> = ({
  color = "primary",
  className,
  children,
  ...props
}) => {
  return (
    <Badge color={color} pill className={classnames("fuze-more-menu__notification", className)} {...props}>
      {children}
    </Badge>
  )
}

export default MoreMenuBadge
