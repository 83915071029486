import { useAsync } from "react-use"
import { getAuthenticationState } from "@fuze/services-auth"
import { useConfigurationContext } from "./useConfigurationContext"

export function useAuthentication() {
  const { authenticationCallback } = useConfigurationContext()
  const query = new URLSearchParams(window.location.search)

  function clearQueryParameters() {
    query.delete("statusCode")
    query.delete("message")
    query.delete("token")
    window.history.replaceState({}, null, `${window.location.pathname}?${query.toString()}`)
  }

  const { value: status, loading, error } = useAsync(async () => {
    const shortLivedToken = query.get("token")
    const authenticationState = await getAuthenticationState(shortLivedToken)

    if (authenticationState === "ready" && shortLivedToken) {
      authenticationCallback()
      clearQueryParameters()
    }

    return authenticationState
  })

  return [status, loading, error]
}
