import React from "react"
import classNames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface IInternalAvatarOverlay {}

const InternalAvatarOverlay: FuzzyFunctionalComponent<IInternalAvatarOverlay> = ({ children, className, ...props }) => {
  return (
    <div className={classNames("fuze-avatar__overlay", className)} {...props}>
      {children}
    </div>
  )
}

export default InternalAvatarOverlay
