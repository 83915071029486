import React, { useContext } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import InternalToggle, { InternalToggleProps } from "./InternalToggle"
import { RadioGroupContext } from "./RadioGroup"

const Radio: FuzzyFunctionalComponent<InternalToggleProps> = ({ children, value, ...props }) => {
  const { onClick, name, selectedValue } = useContext(RadioGroupContext)

  const checked = value === selectedValue

  return (
    <InternalToggle
      checked={checked}
      className="fuze-radio"
      name={name}
      onClick={() => onClick(value)}
      type="radio"
      value={value}
      {...props}
    >
      {children}
    </InternalToggle>
  )
}

export default Radio
