import React from "react"
import classNames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import { Colors } from "@enums"
import { Size } from "src/types"

interface StatusCircleProps {
  color?: Colors
  size?: Size
  height?: string
}

const StatusCircle: FuzzyFunctionalComponent<StatusCircleProps> = ({
  children,
  className,
  color = "grey",
  size,
  height = "1em",
  ...props
}) => {
  const sizeClass = size && `fuze-status-circle__icon--${size}`
  const colorClass = !!color && `fuze-status-circle__icon--${color}`

  return (
    <div className={classNames("fuze-status-circle", className)} {...props}>
      <div
        style={!size ? { height: height, width: height } : {}}
        className={classNames("fuze-status-circle__icon", sizeClass, colorClass)}
      />
      {children}
    </div>
  )
}

export default StatusCircle
