import React from "react"
import { Link, Route } from "react-router-dom"
import { AuthorizationContext } from "./AuthorizationContext"
import { useIsRoutePermitted, useIsRoutePermittedFn, useRouteErrorHandler } from "./useIsRoutePermitted"

function AuthorizationProvider({ isRoutePermitted, routeErrorHandler, children }) {
  return (
    <AuthorizationContext.Provider value={{ isRoutePermitted, routeErrorHandler }}>
      {children}
    </AuthorizationContext.Provider>
  )
}

function ProtectedLink({ to, Tag = Link, ...props }) {
  const [isRoutePermitted] = useIsRoutePermitted(to)

  if (isRoutePermitted) {
    return <Tag to={to} {...props} />
  }
  return null
}

/**
 * This works identically to ProtectedLink except that it always renders the children. This is useful
 * if you want to show an inline link but the user may not have access to click on the link. The
 * prevents missing text
 *
 */
function ConditionalProtectedLink({ to, Tag = Link, children, ...props }) {
  const [isRoutePermitted] = useIsRoutePermitted(to)

  if (isRoutePermitted) {
    return (
      <Tag to={to} {...props}>
        {children}
      </Tag>
    )
  }

  return children
}

/**
 * Generate a three element array
 *
 * The first element is a React component (which is always defined). It will only render it's children
 * if the user has the required permissions/enablements
 *
 * The second element is the Link component
 *
 * The third element is whether or not the user has the required permissions/enablements.
 *
 * You would use this rather than ProtectedLink ift here is html around the link that you don't
 * want to render if the user doesn't have the required permissions/enablements
 *
 * @param {string} to the path to link to
 * @param {Component} Tag an optional override of the Link component
 */
function useProtectedLinkSection(to, Tag = Link) {
  const [isRoutePermitted] = useIsRoutePermitted(to)

  const Section = ({ children }) => (isRoutePermitted ? children : null)
  const SectionLink = ({ ...props }) => <Tag to={to} {...props} />

  return [Section, SectionLink, isRoutePermitted]
}

/**
 * Generate a list of three element arrays
 *
 * The first element is a React component (which is always defined). It will only render it's children
 * if the user has the required permissions/enablements
 *
 * The second element is the Link component
 *
 * The third element is whether or not the user has the required permissions/enablements.
 *
 * This is an advanced version of ProtectedLink when there is html that surrounds
 * the link that should only be rendered when the Link is rendered
 *
 * @param {string[]} tos the list of paths to link to
 * @param {Component} Tag an optional override of the Link component
 */
function useProtectedLinks(tos, Tag = Link) {
  const isRoutePermittedFn = useIsRoutePermittedFn()

  const Links = tos.map(to => {
    const [isRoutePermitted] = isRoutePermittedFn(to)
    if (isRoutePermitted) {
      return ({ ...props }) => <Tag to={to} {...props} />
    }
    return null
  })

  const hasAnyAccess = Links.some(Link => !!Link)

  return [Links, hasAnyAccess]
}

/**
 * Generate a route that ensures the user has access to
 */
function ProtectedRoute({ path, Tag = Route, children, render, ...props }) {
  const [isRoutePermitted, reason, route] = useIsRoutePermitted(path)
  const routeErrorHandler = useRouteErrorHandler()

  if (!route) {
    return null
  }

  return (
    <Tag
      path={path}
      {...props}
      render={routeProps => {
        if (!isRoutePermitted) {
          return routeErrorHandler(reason)
        }
        if (render) {
          return render(routeProps)
        }
        return children
      }}
    />
  )
}

export {
  AuthorizationProvider,
  ProtectedLink,
  ConditionalProtectedLink,
  ProtectedRoute,
  useProtectedLinks,
  useProtectedLinkSection
}
