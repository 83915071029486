import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"
import { Colors } from "@enums"

interface IRadioCardHeader {
  color?: Colors
}

const RadioCardHeader: FuzzyFunctionalComponent<IRadioCardHeader> = ({ children, className, color, ...props }) => {
  const colorClassName = color && `fuze-radio-card__header--color-${color}`

  return (
    <div className={classNames("fuze-radio-card__header", className, colorClassName)} {...props}>
      {children}
    </div>
  )
}

export default RadioCardHeader
