import React from "react"
import classnames from "classnames"
import { Spinner } from "reactstrap"

interface PanelProps {
  className?: string
  children: React.ReactNode
  isLoading?: boolean
}

interface IPanelContext {
  isLoading: boolean
}

export const PanelContext = React.createContext<IPanelContext>(undefined)

const Panel = React.forwardRef<HTMLUListElement, PanelProps>(({ children, className, isLoading, ...props }, ref) => {
  return (
    <ul
      className={classnames("panel", className, {
        "panel--is-loading": isLoading,
      })}
      ref={ref}
      {...props}
    >
      <PanelContext.Provider value={{ isLoading }}>{children}</PanelContext.Provider>
      {isLoading && (
        <li className="list-item-panel--loading">
          <Spinner className="panel__spinner" />
        </li>
      )}
    </ul>
  )
})

export default Panel
