import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface NotificationCardFooterProps {}

const NotificationCardFooter: FuzzyFunctionalComponent<NotificationCardFooterProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div className={classnames("notification-card__footer", className)} {...props}>
      {children}
    </div>
  )
}

export default NotificationCardFooter
