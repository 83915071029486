import React, { useContext } from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import { PanelContext } from "./Panel"

interface PanelHeaderActionsProps {}

const PanelHeaderActions: FuzzyFunctionalComponent<PanelHeaderActionsProps> = ({ children, className, ...props }) => {
  const { isLoading } = useContext(PanelContext)

  if (isLoading) {
    return null
  }

  return (
    <div className={classnames("actions panel__header_actions", className)} {...props}>
      {children}
    </div>
  )
}

export default PanelHeaderActions
