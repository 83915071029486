import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"

interface NavigationTabs {}

const NavigationTabs: FuzzyFunctionalComponent<NavigationTabs> = ({ children, className, ...props }) => {
  return (
    <div className={classNames("fuze-tabs fuze-navigation-tabs", className)} {...props}>
      {children}
    </div>
  )
}

export default NavigationTabs
