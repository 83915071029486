import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import InternalAvatarOverlay from "./InternalAvatarOverlay"

interface IAvatarInitials {}

const AvatarInitials: FuzzyFunctionalComponent<IAvatarInitials> = ({ children }) => {
  return <InternalAvatarOverlay className="fuze-avatar__overlay--initials">{children}</InternalAvatarOverlay>
}

export default AvatarInitials
