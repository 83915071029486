import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface FuzeModalSubHeaderProps {}

const FuzeModalSubHeader: FuzzyFunctionalComponent<FuzeModalSubHeaderProps> = ({ className, ...props }) => {
  return <div className={classnames("fuze-modal__subheader modal-subheader", className)} {...props} />
}

export { FuzeModalSubHeader as ModalSubHeader }
