import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface PageInsetProps {
  className?: string
}

const PageInset: FuzzyFunctionalComponent<PageInsetProps> = ({ children, className, ...props }) => {
  return (
    <div className={classnames("fuze-page__inset", className)} {...props}>
      {children}
    </div>
  )
}

export default PageInset
