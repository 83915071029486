import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface NotificationCardTitle {}

const NotificationCardTitle: FuzzyFunctionalComponent<NotificationCardTitle> = ({ className, children, ...props }) => {
  return (
    <div className={classnames("notification-card__title", className)} {...props}>
      {children}
    </div>
  )
}

export default NotificationCardTitle
