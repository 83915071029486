import React from "react"
import classnames from "classnames"
import { Modal, ModalProps } from "reactstrap"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import { Colors } from "@enums"

export const ModalContext = React.createContext<ModalProps>(undefined)

interface FuzeModalProps {
  color: Colors
}

const FuzeModal: FuzzyFunctionalComponent<FuzeModalProps & ModalProps> = ({
  children,
  className,
  color,
  toggle,
  ...props
}) => {
  return (
    <ModalContext.Provider value={{ color, toggle }}>
      <Modal className={classnames(className, "fuze-modal")} scrollable toggle={toggle} centered size="md" {...props}>
        {children}
      </Modal>
    </ModalContext.Provider>
  )
}
export { FuzeModal as Modal }
