import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"
import { Alignment } from "@enums"

interface ITabsList {
  align: Alignment
  ariaLabel: string
}

const TabsList: FuzzyFunctionalComponent<ITabsList> = ({ align, ariaLabel, children, className, ...props }) => {
  const alignMentClass = align && `fuze-tabs__tab-list--${align}`

  return (
    <div
      className={classNames("fuze-tabs__tab-list", className, alignMentClass)}
      role="tablist"
      aria-label={ariaLabel}
      {...props}
    >
      {React.Children.map(children, (element: JSX.Element, index: number) => React.cloneElement(element, { index }))}
    </div>
  )
}

export default TabsList
