import { useConfigurationContext } from "./useConfigurationContext"

export function useGetUniversalLoginUrl(mode = "") {
  const { warden } = useConfigurationContext()

  // reconstructing the redirect back url to exclude the token, statusCode and msg query params
  const locationParams = parseKeyValues(window.location.search.replace("?", ""))
  let { host, pathname, protocol } = window.location
  // warden might redirect to /undefined when it's having trouble invalidating a token (401)
  pathname = pathname.replace("undefined", "")
  const params = serializeKeyValues(locationParams)

  const errorPaths = ["/404", "/unauthorized"]

  delete locationParams.token
  delete locationParams.statusCode
  delete locationParams.msg

  let newHref = `${protocol}//${host}`

  if (!errorPaths.includes(pathname)) {
    newHref += `${pathname}&${params}`
  }

  // query mode is the default and should not be explicitly used
  const queryParams = {
    accessToken: warden.appKey,
    redirectUri: newHref,
    showRememberMe: true,
    mode
  }
  const serializedQueryParams = serializeKeyValues(queryParams)

  const universalLoginUrl = `${warden.universalLoginUrl}?${serializedQueryParams}`
  return universalLoginUrl
}

/**
 *
 * @param {string} queryString Assumes a format of ?a=b&c=d&e=f
 */
function parseKeyValues(keyValueList, delimiter = "&", kvDelimiter = "=") {
  if (!keyValueList) return {}

  return keyValueList
    .split(delimiter)
    .map(x => x.split(kvDelimiter))
    .filter(x => x.length === 2) // ignore if there is no value (ie. a&b=c)
    .reduce((accumulator, item) => {
      return { ...accumulator, [item[0].trim()]: item[1].trim() }
    }, {})
}

/**
 *
 * @param {Object} map
 * @param {string} delimiter
 * @param {string} kvDelimiter
 */
function serializeKeyValues(map, delimiter = "&", kvDelimiter = "=") {
  const list = mapToKeyValueList(map)
  return list.map(item => `${item.key}${kvDelimiter}${item.value}`).join(delimiter)
}

/**
 * Converts an Javascript Object into a list of key values based on the Object properties
 *
 * @param {Object} map
 * @param {string} keyName a key to use for the property
 * @param {string} valueName a key to use for the property value
 *
 * ie.
 * mapToKeyValueList({a: 1, b: 2}) => [{ key: 'a', value: 1 }, { key: 'b', value: 2 }]
 * mapToKeyValueList({a: 1, b: 2}, 'customKey', 'customValue')
 * 	=> [{ customKey: 'a', customValue: 1 }, { customKey: 'b', customValue: 2 }]
 */
function mapToKeyValueList(map, keyName = "key", valueName = "value") {
  const list = []
  for (let property in map) {
    if (map.hasOwnProperty(property)) {
      if (!!map[property]) {
        list.push({ [keyName]: property, [valueName]: map[property] })
      }
    }
  }
  return list
}
