import React from "react"
import classNames from "classnames"
import { StarsProps } from "../../interfaces/starRating"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface StarRatingProps extends StarsProps {
  arialLabel: string
  className?: string
  position?: string
}

const Stars: FuzzyFunctionalComponent<StarsProps> = ({ rating }) => {
  const starsArray = new Array(5).fill("stroke").fill("filled", 0, rating)

  return (
    <>
      {starsArray.map((icon, index) => (
        <i key={`star-${index}`} className={`icons icon-star_${icon}`} aria-hidden="true" />
      ))}
    </>
  )
}

const StarRating: FuzzyFunctionalComponent<StarRatingProps> = ({
  arialLabel,
  children,
  className,
  position = "right",
  rating,
  ...props
}) => {
  return (
    <span
      className={classNames("star-rating", className, `star-rating--${rating}`, `star-rating--${position}`)}
      {...props}
    >
      <span className="star-rating__label">{children}</span>

      {rating > 0 && (
        <span className="star-rating__stars" role="img" aria-label={arialLabel}>
          <Stars rating={rating} />
        </span>
      )}
    </span>
  )
}

export default StarRating
