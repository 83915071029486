import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface NotificationCardMessageProps {}

const NotificationCardMessage: FuzzyFunctionalComponent<NotificationCardMessageProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div className={classnames("notification-card__message", className)} {...props}>
      {children}
    </div>
  )
}

export default NotificationCardMessage
