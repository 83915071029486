import React, { useContext } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classnames from "classnames"
import { TabsContext } from "./Tabs"

interface ITab {
  id: string | number
}

const Tab: FuzzyFunctionalComponent<ITab> = ({ children, className, id, ...props }) => {
  const { activeTab, onTabClick } = useContext(TabsContext)

  const isActive = id === activeTab

  return (
    <button
      aria-selected={isActive}
      aria-controls={`panel-${id}`}
      id={`tab-${id}`}
      className={classnames(className, "fuze-tabs__tab", {
        "fuze-tablist__tab--active": isActive,
      })}
      onClick={() => onTabClick(id)}
      role="tab"
      tabIndex={isActive ? 0 : -1}
      type="button"
      {...props}
    >
      {children}
    </button>
  )
}

export default Tab
