import React from "react"
import classNames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

export interface ILabelTitle {}

const LabelTitle: FuzzyFunctionalComponent<ILabelTitle> = ({ children, className, ...props }) => {
  return (
    <span className={classNames("fuze-label-title", className)} {...props}>
      {children}
    </span>
  )
}

export default LabelTitle
