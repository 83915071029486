import React from "react"
import {
  Route as InternalRoute,
  Link as InternalLink,
  NavLink as InternalNavLink,
  Redirect as InternalRedirect
} from "react-router-dom"
import { useIsInternalPath } from "./hooks"

function Link({ to, children, ...props }) {
  const isInternalPath = useIsInternalPath(to)

  if (isInternalPath) {
    return (
      <InternalLink to={to} {...props}>
        {children}
      </InternalLink>
    )
  }
  return (
    <ExternalLink to={to} {...props}>
      {children}
    </ExternalLink>
  )
}

function NavLink({ to, children, ...props }) {
  const isInternalPath = useIsInternalPath(to)

  if (isInternalPath) {
    return (
      <InternalNavLink to={to} {...props}>
        {children}
      </InternalNavLink>
    )
  }
  return (
    <ExternalLink to={to} {...props}>
      {children}
    </ExternalLink>
  )
}

/**
 * Rule broken: a component should always return something.
 *
 * In this case, this is a very low-level component that is meant to match
 * the usage of react-router's Redirect component, which also doesn't actually
 * render anything
 *
 * For this reason, I didn't move this into it's own file because I want
 * the decision to use this component to be carefully considered.
 */
function ExternalRedirect({ to }) {
  window.location.href = to
  return null
}

function ExternalLink({ to, children, ...props }) {
  return (
    <a href={to} {...props}>
      {children}
    </a>
  )
}

function Redirect({ to, ...props }) {
  const isInternalPath = useIsInternalPath(to)

  if (isInternalPath) {
    return <InternalRedirect to={to} {...props} />
  }
  return <ExternalRedirect to={to} />
}

/**
 * Route renders the path if it belongs to the app.
 * @param {string} path
 */
function Route({ path, ...props }) {
  const isInternalPath = useIsInternalPath(path)

  // Falsy path check is for use-query-params needing ReactRouterRoute to work
  if (isInternalPath || !path) {
    return <InternalRoute path={path} {...props} />
  }

  return null
}

export { Link, NavLink, Redirect, Route }
