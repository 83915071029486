import React from "react"
import classNames from "classnames"
import { Alert as ReactstrapAlert } from "reactstrap"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import { Colors } from "@enums"

enum AlertColors {
  Medium = "medium",
}

interface AlertProps {
  color?: Colors | AlertColors
}

const Alert: FuzzyFunctionalComponent<AlertProps> = ({ children, className, color = AlertColors.Medium, ...props }) => {
  return (
    <ReactstrapAlert color={color} className={classNames("fuze-alert", className)} role="alert" {...props}>
      {children}
    </ReactstrapAlert>
  )
}

export default Alert
