import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import InternalCheckbox, { IInternalCheckbox } from "./InternalCheckbox"

enum Position {
  Left = "left",
  Right = "right",
}

interface ToggleSwitchProps {
  position?: Position
}

const ToggleSwitch: FuzzyFunctionalComponent<IInternalCheckbox & ToggleSwitchProps> = ({
  className,
  children,
  position = Position.Left,
  ...props
}) => {
  return (
    <InternalCheckbox
      className={classnames("fuze-toggle-switch", `fuze-toggle-switch--${position}`, className)}
      {...props}
    >
      {children}
    </InternalCheckbox>
  )
}

export default ToggleSwitch
