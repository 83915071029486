import React, { useContext } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import InternalToggle, { InternalToggleProps } from "./InternalToggle"
import { RadioGroupContext } from "./RadioGroup"
import { Card } from "../Cards/Cards"
import classNames from "classnames"

const RadioCard: FuzzyFunctionalComponent<InternalToggleProps> = ({ children, className, value, ...props }) => {
  const radioGroupContext = useContext(RadioGroupContext)

  if (!radioGroupContext) {
    return null
  }

  const { onClick, name, selectedValue } = radioGroupContext

  const checked = value === selectedValue

  return (
    <InternalToggle
      checked={checked}
      className={classNames(className, "fuze-radio-card")}
      name={name}
      onClick={() => onClick(value)}
      type="radio"
      value={value}
      {...props}
    >
      <Card className="fuze-radio-card__card">{children}</Card>
    </InternalToggle>
  )
}

export default RadioCard
