import React, { useContext } from "react"
import classnames from "classnames"
import _compact from "lodash/compact"
import _castArray from "lodash/castArray"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import { PanelContext } from "./Panel"

interface PanelListItemProps {
  showWhileLoading?: boolean
}

const PanelListItem: FuzzyFunctionalComponent<PanelListItemProps> = ({
  children,
  className,
  showWhileLoading = false,
}) => {
  const { isLoading } = useContext(PanelContext)

  if (isLoading && !showWhileLoading) {
    return null
  }

  return <li className={classnames("panel__list-item", className)}>{children}</li>
}

export default PanelListItem
