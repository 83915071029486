import React, { useEffect, useState } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"

interface ITabs {
  defaultSelected: string | number
  onTabChange?: Function
}

interface ITabsContext {
  activeTab: string | number
  onTabChange?: Function
  onTabClick: Function
}

export const TabsContext = React.createContext<ITabsContext>(undefined)

const Tabs: FuzzyFunctionalComponent<ITabs> = ({ children, className, defaultSelected, onTabChange, ...props }) => {
  const [activeTab, setActiveTab] = useState(defaultSelected)

  useEffect(() => {
    if (onTabChange) {
      onTabChange(activeTab)
    }
  }, [activeTab, onTabChange])

  const onTabClick = (value: string | number) => {
    setActiveTab(value)
  }

  return (
    <div className={classNames("fuze-tabs", className)} {...props}>
      <TabsContext.Provider value={{ activeTab, onTabClick }}>{children}</TabsContext.Provider>
    </div>
  )
}

export default Tabs
