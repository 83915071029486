import { useState } from "react"
import { removeToken } from "@fuze/services-auth/dist/token"
import { closeUserSession } from "@fuze/services-auth/dist/session-tasks"
import { useGetUniversalLoginUrl } from "./useGetUniversalLoginUrl"

export function useLoginUrl() {
  const loginUrl = useGetUniversalLoginUrl()
  return loginUrl
}

export function useLogoutUrl() {
  const [isLoggingOut, setLoggingOut] = useState(false)
  const logoutUrl = useGetUniversalLoginUrl("logout")

  function logout() {
    removeToken() // remove the token
    closeUserSession() // Cleaning up user session variables

    setLoggingOut(true)
  }

  return [isLoggingOut, logoutUrl, logout]
}
